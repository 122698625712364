import React,{useState,useEffect}from "react"
import {graphql ,Link } from "gatsby"
import Layout from "@components/layout"
import Meta from "@components/meta"
import BottomCta from "@components/bottom-cta"
import '@styles/pages/news-article.scss'
import RightArrowIcon from "@images/svg/icon-right-arrow_white_solid.svg"
import AniLink from "gatsby-plugin-transition-link/AniLink";

import posed, { PoseGroup } from 'react-pose'
const transition = {
  visible: { opacity: 1,delay: 600, },
  hidden: { opacity: 0,delay: 600}
}
const Box = posed.div(transition)


const NewsArticle = (props) => {
  const [path, setPath] = useState(false);

  useEffect(()=> {
    setPath(true);
    return ()=> setPath(false);
  })
  const datetime = props.data.microcmsNews.publishedAt.slice( 0, 10 ) ;
  return (
    <Layout>
      <Meta
        title="ニュースタイトル"
      />
        <PoseGroup>
          <Box key={"news-article"} pose={path ? "visible" : "hidden"}>
        <section class="page">
          <div class="page-top">
            <div class="page-top__container">
              <ol>
              <li><AniLink cover duration={0.6} bg="#fff" direction="down" to="/">TOP&nbsp;>&nbsp;</AniLink></li>
                <li>お知らせ</li>
              </ol>
              <h2 class="page-top__heading">{props.data.microcmsNews.title}</h2>
              <p class="page-top__lead"><time datetime={datetime}>{datetime}</time></p>
            </div>
          </div>
          <div class="section page-section news-article">
            <div class="section__container">
            <div
              class="news-content"
              dangerouslySetInnerHTML={{
                __html: `${props.data.microcmsNews.body}`,
              }}
            />
            </div>
            <p class="btn btn--small btn--small--solid"><Link to="/news"><span>お知らせ一覧へ</span><RightArrowIcon /></Link></p>
          </div>
        </section>
        <BottomCta/>
        </Box>
        </PoseGroup>
    </Layout>
  )
}
export default NewsArticle

export const query = graphql`
  query($id: String!) {
    microcmsNews(id: { eq: $id }) {
      newsId
      title
      updatedAt
      body
      publishedAt
      newsId
    }
  }
`